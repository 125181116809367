import React, { Component } from "react";

import Notiflix from "notiflix";
import PostApiCall from "./components/helper/Api";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import Parser from "html-react-parser";
import Header from "./Header";
import Footer from "./Footer";
import { ToWords } from "to-words";

import Searchicon from "./CustomerDashboard/images/search.png";
// import InvoiceForm from "../InvoiceForm"
import logo from "./assets/image/PioLogo.png";

import "./index.css";
import { Link } from "react-router-dom";

class MyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderHistoryData: [],
      searchInput: "",
      MainOrder: [],
      MainOrders: [],
      OrderNumber: "",
      DeliveryDetails: "",
    };
  }

  componentDidMount() {
    Notiflix.Loading.init({
      svgColor: "#ee1b2f",
    });

    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid} order by fld_order_id desc`,
      },
      "GetCustomerOrderDetails"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data);
          this.setState({
            orderHistoryData: obj.data,
          });
          Notiflix.Loading.remove();
        }
      })
    );
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };

  DownloadInvoicePdf() {
    var html2pdf = require("html2pdf.js");
    var element = document.getElementById("invoiceForm");
    var opt = {
      margin: 1,
      filename: this.state.OrderNumber + "-orderform.pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: {
        scale: 1.5,
        useCORS: true,
        allowTaint: true,
        proxy: null,
        dpi: 192,
      },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
        compress: true,
      },
      //   pagebreak: {avoid: ‘div’}
    };
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        Notiflix.Loading.remove();
      });
  }

  render() {
    // console.log(this.state.orderHistoryData) ;
    const data = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 75,
        },
        {
          label: "Order Number",
          field: "number",
          sort: "disabled",
          width: 600,
        },

        {
          label: "Order Date",
          field: "date",
          sort: "disabled",
          width: 600,
        },

        {
          label: "Order Value",
          field: "ordervalue",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Payment Status",
          field: "paystatus",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Order Status",
          field: "status",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Download Invoice",
          field: "downloadinvoice",
          sort: "disabled",
          width: 200,
        },
        {
          label: "View Order",
          field: "invoice",
          sort: "disabled",
          width: 200,
        },

        {
          label: "Download Keyline",
          field: "keyline",
          sort: "disabled",
          width: 200,
        },

        // {
        //     label: "Action",
        //     field: "action",
        //     sort: "disabled",
        //     width: 100,
        // },
      ],
      rows: this.state.orderHistoryData
        .filter((data) => {
          if (this.state.searchInput == "") {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_order_status
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_order_number
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_payment_status
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
        })
        .map((data, i) => {
          return {
            serial: i + 1,
            number: data.fld_order_number,
            date: data.fld_order_date,

            // item: (data.total_items),
            ordervalue: data.fld_order_amount,
            paystatus:
              data.fld_payment_mode == "COD"
                ? "Cash On Delivery"
                : data.fld_payment_status,
            status: data.fld_order_status,
            invoice: (
              <p>
                <Link to={`/view-invoice-form/${data.fld_order_id}`}>
                  <span>
                    <i
                      class="fa fa-eye viewicon"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                      // onClick={() => {
                      //   window.location.href = `/view-invoice-form/${data.fld_order_id}`;
                      // }}
                    />
                  </span>
                </Link>
                <span
                  style={{ paddingRight: "10px", color: "#59c5d1" }}
                  className="pipe"
                >
                  |
                </span>
                <span>
                  <i
                    class="fa fa-download"
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      PostApiCall.postRequest(
                        {
                          whereClause: `where fld_order_id=${parseInt(
                            data.fld_order_id
                          )}`,
                        },
                        "GetWebsiteOrderDetails"
                      ).then((results1) =>
                        results1.json().then((obj) => {
                          if (
                            results1.status == 200 ||
                            results1.status == 201
                          ) {
                            this.setState({
                              MainOrder: obj.data,
                              MainOrders: obj.data[0],
                              Payment_Mode: obj.data[0].Payment_Mode,
                              OrderNumber: obj.data[0].fld_orderNumber,
                              DeliveryDetails:
                                obj.data[0].Shipping_Charg != 0
                                  ? "Delivery Partner"
                                  : "Self Pickup",
                            });
                            this.state.MainOrder.map((data, i) => {
                              if (i === 0) {
                                const toWords = new ToWords();
                                let words = toWords.convert(
                                  data.Total_Inclusive_Tax
                                );
                                this.setState(
                                  {
                                    AmountInWords: words,
                                  },
                                  () => {
                                    this.DownloadInvoicePdf();
                                  }
                                );
                              }
                            });
                          }
                        })
                      );
                    }}
                  ></i>
                </span>
              </p>
            ),

            downloadinvoice: (
              <a
                data-title="Not Available"
                href={data.fld_invoice_file}
                download
              >
                {/* {" "} */}
                {data.fld_invoice_file === null ? (
                  <i
                    class="fa-solid fa-ban banicon"
                    aria-hidden="true"
                    style={{ color: "#59c5d1" }}
                  ></i>
                ) : (
                  // <img src={NA} style={{width:"18%"}}></img>
                  <i
                    class="fa fa-download"
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                  ></i>
                )}
              </a>
            ),
            keyline: (
              <a href={data.fld_key_line_doc} download>
                <i
                  class="fa fa-download"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                />
              </a>
            ),
          };
        }),
    };

    return (
      <React.Fragment>
        <Header />

        <section
          style={{ paddingTop: "60px", paddingBottom: "20px" }}
        ></section>
<Container fluid>
        <Card className="mini-stats-wid">
          <div className="row">
            <div className="col-md-12">
              <div className="overflow-hidden website-card">
                <div
                  className="globalTrendzEcomm-bg-soft-pink"
                  style={{ height: "85px", background: "#59c5d1" }}
                >
                  <h4 className="mb-0 productTitle">My Orders</h4>
                </div>
              </div>
            </div>
          </div>
          <CardBody>
            <Row>
              <MDBCol
                md="12"
                className="search-box"
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      <span className="">
                        <img
                          src={Searchicon}
                          alt="block user"
                          className="btnicons"
                        ></img>
                      </span>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control my-orders"
                    placeholder="Type the search phrase you wish to search within the grid"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    onChange={(e) => this.seachBarHandler(e)}
                    value={this.state.searchInput}
                  />
                </div>
              </MDBCol>

              <Col md="12">
                {this.state.orderHistoryData.length != 0 ? (
                  <div className="mdbtable">
                    <MDBDataTable
                      hover
                      // scrollY
                      striped
                      bordered
                      data={data}
                      seachTop={false}
                      entriesOptions={[10, 25, 50, 100]}
                    >
                      <MDBTableHead columns={data.columns} />
                      <MDBTableBody rows={data.rows} />
                    </MDBDataTable>
                  </div>
                ) : (
                  <p>No Data Found</p>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
        </Container>
        <div style={{ display: "none" }}>
          <div id="invoiceForm">
            <table
              style={{
                width: "700px",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                bottom: "0px",
                borderRightColor: "#000",
                marginTop: "30px",
                borderCollapse: "collapse",
              }}
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tbody>
                <tr>
                  <td
                    colspan="2"
                    style={{ width: "20%", verticalAlign: "middle" }}
                  >
                    <img
                      src={logo}
                      alt="logoinvoiceform"
                      style={{
                        width: "65%",
                        marginRight: "auto",
                        marginLeft: "auto",
                        verticalAlign: "middle",
                      }}
                    />{" "}
                  </td>
                  <td colspan="8" style={{ width: "80%" }}>
                    {" "}
                    <h2
                      style={{
                        textAlign: "center",
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      PIO PRINTERS PRIVATE LIMITED
                    </h2>
                    <p style={{ textAlign: "center" }}>
                      83, East Mada Church Road, Royapuram,Chennai - 600 013
                      India
                      <br />
                      Phone: +91 98403 27012
                      <br />
                      GST: 33AAACP6506F1ZT
                    </p>
                    <tr
                      rowspan="8"
                      class="success"
                      style={{
                        display: "table",
                        width: "100%",
                        backgroundColor: "#f7f7f7",
                        borderBottom: "none",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                    >
                      <td
                        colspan="8"
                        style={{
                          textAlign: "center",
                          paddingRight: "1%",
                          fontWeight: "bold",
                          fontSize: "20px",
                        }}
                      >
                        Customer Order Form
                      </td>
                    </tr>
                  </td>
                </tr>

                {this.state.MainOrder.map((data, i) => {
                  if (i === 0) {
                    return (
                      <tr>
                        <td
                          colspan="1"
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            Order Date
                          </span>
                        </td>

                        <td
                          colspan="3"
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          {data.fld_order_date}
                        </td>

                        <td
                          colspan="3"
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            Customer Order No.
                          </span>
                        </td>
                        <td
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          {data.fld_orderNumber}
                        </td>
                      </tr>
                    );
                  }
                })}

                <tr class="success">
                  <td
                    colspan="6"
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      fontWeight: "bold",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    Billing Address
                  </td>
                  <td
                    colspan="6"
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      fontWeight: "bold",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    Shipping Address
                  </td>
                </tr>
                {this.state.MainOrder.map((data, i) => {
                  if (i === 0) {
                    return (
                      <tr>
                        <td
                          colspan="6"
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          <p>
                            {data.fld_contactperson}
                            <br />
                            {data.fld_billing_company_name}
                            <br />
                            {data.fld_address}, {data.fld_landmark}
                            <br />
                            {data.fld_city}, {data.fld_state}-{data.fld_Pincode}
                            , {data.fld_country},
                            <br />
                            <b> Phone:</b> (+91) {data.fld_mobile}
                            <br />
                            <b> Email:</b> {data.fld_billingemail}
                            <br />
                            <b
                              style={{
                                display:
                                  data.fld_GSTIN == ""
                                    ? "none"
                                    : "inline-block",
                              }}
                            >
                              {" "}
                              GSTIN:
                            </b>{" "}
                            {data.fld_GSTIN}
                          </p>
                        </td>

                        <td
                          colspan="6"
                          style={{
                            textAlign: "left",
                            paddingLeft: "1%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                          }}
                        >
                          <p>
                            {data.fld_shippingcontactperson}
                            <br />
                            {data.fld_shipping_company_name}
                            <br />
                            {data.fld_shippingaddress}{" "}
                            {data.fld_shippinglandmark}
                            <br /> {data.fld_shippingcity},{" "}
                            {data.fld_shippingstate}-{data.fld_shippingPincode}{" "}
                            , {data.fld_shippingcountry}.
                            <br />
                            <b> Phone: </b> (+91) {data.fld_shippingmobile}
                            <br />
                            <b> Email: </b> {data.fld_email}
                            <br />
                            <b
                              style={{
                                display:
                                  data.fld_shippingGSTIN == ""
                                    ? "none"
                                    : "inline-block",
                              }}
                            >
                              {" "}
                              GSTIN:
                            </b>{" "}
                            {data.fld_shippingGSTIN}
                          </p>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
            <table
              style={{
                width: "700px",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                borderRightColor: "#000",
                borderTop: "hidden",
              }}
              border="1"
              cellspacing="0"
              cellPadding="0"
            >
              <tbody>
                <tr class="success" style={{fontFamily: "Lato, sans-serif"}}>
                  <td
                    // colSpan={3}
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                      width: "34%"
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Product</span>
                  </td>
                  <td
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Quantity</span>
                  </td>
                  <td
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Unit Price</span>
                  </td>
                  <td
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Net Value</span>
                  </td>

                  <td
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Shipping cost</span>
                  </td>
                  <td
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Total Amount (INR)
                    </span>
                  </td>
                  {/* <!--   <td style="padding-top: 1%; padding-bottom: 1%;text-align:center"><span style={{fontWeight:'bold'}}></span></td> -->
                   */}
                </tr>
                {this.state.MainOrder.map((data, i) => (
                  <tr>
                    <td
                      // colSpan={3}
                      style={{
                        textAlign: "center",
                        paddingLeft: "1%",
                        paddingTop: "1%",
                        paddingBottom: "1%",
                      }}

                      // dangerouslySetInnerHTML={{
                      //   __html: data.fld_custom_item_description,
                      // }}
                    >
                       {Parser(data.fld_custom_item_description)}
                    </td>
                    <td style={{ textAlign: "center" }}>{data.fld_quantity}</td>
                    <td style={{ textAlign: "center" }}>
                    &#8377;{parseFloat(data.fld_unit_price).toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                    &#8377;&nbsp;{parseFloat(data.fld_netamount_without_GST_ship_amount).toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                    &#8377;&nbsp;{parseFloat(data.Shipping_Charg).toFixed(2)}
                    </td>

                    <td style={{ textAlign: "center", paddingRight: "1%" }}>
                    &#8377;&nbsp; {parseFloat(data.fld_netamount_without_GSTamount).toFixed(2)}
                    </td>
                    {/* <td style={{ textAlign: "center" }}>
                      {data.fld_quantity} Nos
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {data.fld_unit_price}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {parseFloat(data.fld_netvalue).toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {" "}
                      {parseFloat(data.Shipping_Charg).toFixed(2)}
                    </td>

                    <td style={{ textAlign: "right", paddingRight: "1%" }}>
                      {parseFloat(data.Total_Inclusive_Tax).toFixed(2)}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>

            <table
              style={{
                width: "700px",
                padding: "2%",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                borderRightColor: "#000",
                borderTop: "hidden",
              }}
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Dispatched by Courier partner
                    </span>
                  </td>
                  {this.state.MainOrder.map((data, i) => {
                    if (i === 0) {
                      return (
                        <>
                          <td style={{ width: "70%" }}>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  padding: "1%",
                                  width: "31%",
                                }}
                              >
                                <span style={{ fontWeight: "bold",fontFamily: "Lato, sans-serif" }}>
                                  Sub total
                                </span>
                              </td>

                              <td
                                style={{
                                  textAlign: "left",
                                      paddingLeft: "28%",
                                  width: "28%",
                                }}
                              >
                                {" "}
                                &#8377;&nbsp;
                                {data.fld_netamount_without_GSTamount}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  padding: "1%",
                                  // width: "40%",
                                }}
                              >
                                <span style={{ fontWeight: "bold",fontFamily: "Lato, sans-serif" }}>
                                  GST(18%)
                                </span>
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                      paddingLeft: "28%",
                                  // width: "20%",
                                }}
                              >
                                {" "}
                                &#8377;&nbsp;
                                {data.fld_GSTamount}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  padding: "1%",
                                  // width: "40%",
                                }}
                              >
                                <span style={{ fontWeight: "bold",fontFamily: "Lato, sans-serif" }}>
                                  Total (Inclusive of all Taxes)
                                </span>
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                      paddingLeft: "28%",
                                  // width: "20%",
                                }}
                              >
                                {" "}
                                &#8377;&nbsp;
                                {data.Total_Inclusive_Tax}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  padding: "1%",
                                  // width: "40%",
                                }}
                              >
                                <span style={{ fontWeight: "bold",fontFamily: "Lato, sans-serif" }}>
                                  Payment Mode
                                </span>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  paddingRight: "1%",
                                  // width: "20%",
                                  fontFamily: "Lato, sans-serif"
                                }}
                              >
                                {data.Payment_Mode}
                              </td>
                            </tr>
                          </td>
                        </>
                        // <td>
                        //   <tr style={{ width: "100%", display: "table" }}>
                        //     <td style={{ textAlign: "right", padding: "1%" }}>
                        //       <span style={{ fontWeight: "bold" }}>
                        //         Sub total
                        //       </span>
                        //     </td>
                        //     <td
                        //       style={{
                        //         textAlign: "right",
                        //         paddingRight: "1%",
                        //         width: "35%",
                        //       }}
                        //     >
                        //       {" "}
                        //       &#8377;
                        //       {parseFloat(data.Sub_Total_Amnt).toFixed(2)}
                        //     </td>
                        //   </tr>

                        //   <tr style={{ width: "100%", display: "table" }}>
                        //     <td style={{ textAlign: "right", padding: "1%" }}>
                        //       <span style={{ fontWeight: "bold" }}>
                        //         GST(18%)
                        //       </span>
                        //     </td>
                        //     <td
                        //       style={{
                        //         textAlign: "right",
                        //         paddingRight: "1%",
                        //         width: "35%",
                        //       }}
                        //     >
                        //       {" "}
                        //       &#8377;
                        //       {parseFloat(data.fld_GSTamount).toFixed(2)}
                        //     </td>
                        //   </tr>

                        //   <tr style={{ width: "100%", display: "table" }}>
                        //     <td style={{ textAlign: "right", padding: "1%" }}>
                        //       <span style={{ fontWeight: "bold" }}>
                        //         Total (Inclusive of all Taxes)
                        //       </span>
                        //     </td>
                        //     <td
                        //       style={{
                        //         textAlign: "right",
                        //         paddingRight: "1%",
                        //         width: "35%",
                        //       }}
                        //     >
                        //       {" "}
                        //       &#8377;
                        //       {parseFloat(data.Total_Inclusive_Tax).toFixed(2)}
                        //     </td>
                        //   </tr>
                        // </td>
                      );
                    }
                  })}
                </tr>

                {/* <tr>
                  <td
                    colspan="5"
                    style={{
                      textAlign: "left",
                      paddingLeft: "1%",
                      paddingTop: "1%",
                      paddingBottom: "1%",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Amount in Words:</span>{" "}
                    {this.state.AmountInWords} Only
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="5"
                    style={{
                      textAlign: "left",
                      paddingLeft: "1%",
                      paddingTop: "1%",
                      paddingBottom: "1%",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Payment Mode:</span>{" "}
                    {this.state.Payment_Mode == "payu"
                      ? "PAYU"
                      : this.state.Payment_Mode}
                  </td>
                </tr> */}
              </tbody>
            </table>
            <table
              style={{
                width: "700px",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                borderRightColor: "#000",
                borderTop: "hidden",
                fontFamily: "Lato, sans-serif",
                borderCollapse: "collapse",
                marginBottom: "30px",
              }}
              border="1"
              cellspacing="1"
              cellpadding="0"
            >
              <tbody>
                <tr>
                  <td colspan="4"></td>
                  <td
                    colspan="6"
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                      background: "#f7f7f7",
                    }}
                  >
                    Have a Question?
                    <br /> Call us on 96770 27016 / 98403 27012 or Email us at{" "}
                    <a href="mailto:info@piopackaging.com">
                      info@piopackaging.com
                    </a>
                  </td>
                </tr>

                <tr class="success" style={{ backgroundColor: "#f7f7f7" }}>
                  <td colspan="4"></td>
                  <td
                    colspan="6"
                    style={{
                      paddingTop: "1%",
                      paddingBottom: "1%",
                      textAlign: "center",
                      background: "#f7f7f7",
                    }}
                  >
                    Shop online at : &nbsp;{" "}
                    <a href="https://www.piopackaging.com/ordercustomboxes">
                      https://www.piopackaging.com/ordercustomboxes
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default MyOrders;
