import moment from "moment";
import Notiflix from "notiflix";
import React, { createRef } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { MdCancel } from "react-icons/md";
// import Modal from "react-responsive-modal";
// import "react-responsive-modal/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import DesignInstructions from "../src/assets/DesignInstructions.png";
import DesignInstructionsPdf from "../src/assets/DesignInstructionsforSticker.pdf";
import PostApiCall from "../src/components/helper/Api";
import Footer from "./Footer";
import Header from "./Header";
import piologo from "../src/assets/image/PioLogo.png";

class StickersAndLabelsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineStoreBtn: true,
      materialData: [],
      stickerDataMaster: [],
      sizeData: [],
      shapeData: [],
      quantityData: [],
      selectedShape: null,
      selectedShapeName: null,
      selectedQuantity: null,
      selectedMaterial: null,
      termscondition: "No",
      selectedMaterialName: null,
      selectedSize: null,
      selectedLength: null,
      selectedWidth: null,
      isCustomSize: "N",
      stickerPrice: null,
      stickerUnitPrice: null,
      selectedSticker: null,
      GetStickerAssistance: false,
      selectedStickerImage: null,
      gstAmount: null,
      // showModal: false,
      showPDFModal: false,
      showPrevImage: false,
      stickerDiscount: null,
      stickerCalculation: null,
      shareProductunicicon: null,
      name: "",
      mobile: "",
      selectedBoxColor: "",
      selectedMaterialBox: "",
      minQty: "",

      // For show active slider
      activeSlide: 0,
    };
    this.sliderRef = createRef();
  }

  handleShowPDFModal = () => {
    this.setState({ showPDFModal: true });
  };

  handleClosePDFModal = () => {
    this.setState({ showPDFModal: false });
  };

  showPrevImage = () => {
    this.setState({ showPrevImage: true });
  };

  closePrevImage = () => {
    this.setState({ showPrevImage: false });
  };

  componentDidMount() {
    const store = this.context;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (this.props.location.state != undefined) {
      this.setState({
        selectedStickerImage: this.props.location.state.data.fld_image,
        showPrevImage: this.props.location.state.data.fld_image ? true : false,
        selectedBoxColor: this.props.location.state.data.fld_shape_name,
      });
    }
    {
      isMobile
        ? this.setState({
            onlineStoreBtn: true,
          })
        : this.setState({
            onlineStoreBtn: false,
          });
    }
    PostApiCall.postRequest(
      {
        recordCount: "*",
        WhereClause: "",
      },
      "GetStickerMaster"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          console.log(obj.data);
          this.setState({
            stickerDataMaster: obj.data,
          });
          Notiflix.Loading.remove();
        }
      })
    );
    PostApiCall.postRequest(
      {
        recordCount: "*",
        WhereClause: ` where fld_status = 'Yes' `,
      },
      "GetStickerShapeMaster"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            shapeData: obj.data,
            selectedShape: obj.data[0].fld_id,
            selectedSticker: obj.data[0].fld_id,
            selectedBoxColor:
              this.props.location.state != undefined
                ? this.props.location.state.data.fld_shape_name
                : obj.data[0].fld_shape_name,
            selectedStickerImage:
              this.props.location.state != undefined
                ? this.props.location.state.data.fld_image
                : obj.data[0].fld_image,
            showPrevImage: true,

            // **** For showing active shape item which is click from another component ****
            activeSlide: obj.data.findIndex(
              (sticker) =>
                sticker.fld_shape_name ===
                (this.props.location.state !== undefined
                  ? this.props.location.state.data.fld_shape_name
                  : obj.data[0].fld_shape_name)
            ),
          });

          PostApiCall.postRequest(
            {
              recordCount: "*",
              WhereClause: ` where fld_shape_id = ${obj.data[0].fld_id} and fld_status = 'Yes'`,
            },
            "GetStickerSizeMaster"
          ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                console.log(obj.data);
                this.setState({
                  sizeData: obj.data,
                });
                Notiflix.Loading.remove();
              }
            })
          );
          Notiflix.Loading.remove();
        }
      })
    );
    PostApiCall.postRequest(
      {
        recordCount: "*",
        whereClause: ``,
      },
      "GetStickerQuantityMaster"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            quantityData: obj.data,
          });
          Notiflix.Loading.remove();
        }
      })
    );
    PostApiCall.postRequest(
      {
        recordCount: "*",
        WhereClause: " where fld_status = 'Active' ",
      },
      "GetStickerMaterialMaster"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            materialData: obj.data,
            selectedMaterial: obj.data[0].fld_id,
            selectedMaterialBox: obj.data[0].fld_material_name,
          });
          Notiflix.Loading.remove();
        }
      })
    );
  }

  GetStickerAssistance = () => {
    this.setState({
      GetStickerAssistance: false,
      PickUpType: "SelfPickup",
    });
  };

  GetDeliveryCoastModelPopupModal = (event) => {
    if (event.keyCode === 27) {
      this.GetStickerAssistance();
    }
  };

  onOrderNow = () => {
    Notiflix.Loading.circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var pickuptype =
      this.state.PickUpType == "StandardShipping"
        ? this.state.Pincode
        : this.state.PickUpType;

    this.setState(
      {
        OrderDiscription:
          '<thead class="orderheader"><tr>' +
          '<th class="ordersummarylist2" colSpan="2">Item Description</th>' +
          '<th class="ordersummarylist">Quantity</th>' +
          '<th class="ordersummarylist">Unit Price</th>' +
          '<th class="ordersummarylist">Shipping Cost</th>' +
          '<th class="ordersummarylist">GST (' +
          18 +
          "%)</th>" +
          '<th class="ordersummarylist">Total Amount</th>' +
          "</tr></thead>" +
          '<tbody><tr><td class="item-summary-image" style="width: 5%;">' +
          `<img style="width: 100%" src="${this.state.selectedStickerImage}"></img>` +
          "</td>" +
          '<td style="border-left: hidden">' +
          '<p style="text-align:justify">' +
          "Stickers" +
          "</p>" +
          '<table class="innertable">' +
          "<tr>" +
          "<td>Shape</td><td>: " +
          this.state.selectedShapeName +
          "</td></tr>" +
          "<td>Size</td><td>: " +
          this.state.selectedLength +
          "*" +
          this.state.selectedWidth +
          "</td></tr>" +
          "<tr><td>Material</td><td>: " +
          this.state.selectedMaterialName +
          "</td></tr>" +
          "</table>" +
          "</td>" +
          '<td class="ordersummarylist" style="text-align:center">' +
          this.state.selectedQuantity +
          "</td>" +
          '<td class="ordersummarylist" style="text-align: right">₹' +
          parseFloat(this.state.stickerUnitPrice).toFixed(2) +
          "</td>" +
          '<td class="ordersummarylist" style="text-align: right">₹' +
          parseFloat(0).toFixed(2) +
          "</td>" +
          '<td class="ordersummarylist" style="text-align: right">₹' +
          parseFloat(this.state.gstAmount).toFixed(2) +
          "</td>" +
          '<td class="ordersummarylist" style="text-align: right">₹' +
          parseFloat(this.state.stickerPrice).toFixed(2) +
          "</td>",

        CustomItemDescription: `<table style='text-align:left'>
          <tbody>
              <tr>
                  <td style='width:40%;padding-right:10px'>
                      <img style='width:100%' src="${this.state.selectedStickerImage}" alt= />
                  </td>
              <td>
                  <table>
                      <tr>
                          <td>
                              <p style='text-align:justify'>'Stickers'</p>
                          </td>
                      </tr>
                  <tr>
                      <td><strong>Shape Name</strong></td>
                      <td> : </td>
                      <td>${this.state.selectedShapeName}</td>
                  </tr>
                  <tr>
                      <td><strong>Size</strong></td>
                      <td> : </td>
                      <td>${this.state.selectedSize}</td>
                  </tr>
                   <tr>
                      <td><strong>Material</strong></td>
                      <td> : </td>
                      <td>${this.state.selectedMaterialName}</td>
                  </tr>
                  </table>
              </td>

              </td>
              </tr>
          </tbody>
      </table>`,

        // '<td class="ordersummarylist" style="text-align: right">₹' + this.state.CustomQuantity_Deliverycharge.toLocaleString(undefined, { maximumFractionDigits: 2 }) + '</td>'
      },
      () => {
        PostApiCall.postRequest(
          {
            orderdate: moment().format("lll"),
            itemdetails: `
                                          [
                                          {
                                              "Item":196,
                                              "Quantity":${this.state.selectedQuantity}
                                          }
                                          ]
                                          `,
            orderdescription: this.state.OrderDiscription,
            shippingcharge: this.state.CustomQuantity_Deliverycharge,
            status: "INCART",
            customerid:
              localStorage.getItem("CustomerData") == null
                ? null
                : logindetails[0].fld_customerid,
            createdon: moment().format("lll"),
            updatedon: moment().format("lll"),
            orderid: 0,
            gst: this.state.gstAmount,
            totalamount: this.state.stickerPrice,
            updatedby:
              localStorage.getItem("CustomerData") == null
                ? null
                : logindetails[0].fld_customerid,
            designType: null,
            outercoatingid: null,
            innercoatingid: null,
            boardid: null,
            deliverytype: null,
            CustomItemDescription: this.state.CustomItemDescription,
            designid: null,
            stickerCalculation: this.state.stickerCalculation,
          },
          "AddToCartWebsite"
        ).then((result) =>
          result.json().then((obj) => {
            if (result.status == 200 || result.status == 201) {
              Notiflix.Loading.remove();
              localStorage.setItem("Ordertype", JSON.stringify("Stickers"));
              // Notiflix.Notify.success('Product added to Cart.')
              // console.log(obj.data)
              if (localStorage.getItem("CustomerData") != null) {
                if (localStorage.getItem("OrderData") == null) {
                  localStorage.setItem("OrderData", JSON.stringify(obj.data));

                  window.location.href = "/selectaddress";
                } else {
                  localStorage.setItem("OrderData", JSON.stringify(obj.data));

                  window.location.href = "/selectaddress";
                }
              } else {
                localStorage.setItem("OrderData", JSON.stringify(obj.data));
                localStorage.setItem("currentPage", "Cart");
                window.location.href = "/login";
              }
            } else {
              Notiflix.Loading.remove();
              Notiflix.Notify.failure(obj.data);
            }
          })
        );
      }
    );
  };

  onPostPrice = () => {
    if (this.state.selectedQuantity >= 100) {
      PostApiCall.postRequest(
        {
          quantity: this.state.selectedQuantity,
          shape: this.state.selectedShape,
          digital: "Y",
          material: this.state.selectedMaterial,
          length:
            this.state.isCustomSize === "Y"
              ? this.state.selectedLength
              : this.state.selectedSize?.split("X")[0],
          width:
            this.state.isCustomSize === "Y"
              ? this.state.selectedWidth
              : this.state.selectedSize?.split("X")[1],
        },
        "GetStickerPrice"
      ).then((resultdes1) =>
        resultdes1.json().then((obj6) => {
          this.setState({
            stickerPrice: obj6.data[0].total,
            stickerUnitPrice: obj6.data[0].unitcost,
            gstAmount: obj6.data[0].GST,
            stickerDiscount: obj6.data[0].discountamount,
            stickerCalculation:
              '<thead class="orderheader"><tr>' +
              '<th class="ordersummarylist2">Sticker Length</th>' +
              '<th class="ordersummarylist">Length Bleed</th>' +
              '<th class="ordersummarylist2" >Width Length</th>' +
              '<th class="ordersummarylist">Width Bleed</th>' +
              '<th class="ordersummarylist">Stickers Horizontal</th>' +
              '<th class="ordersummarylist">Stickers Vertical</th>' +
              '<th class="ordersummarylist">Total Stickers</th>' +
              '<th class="ordersummarylist">Trial Sheets</th>' +
              '<th class="ordersummarylist">Total Sheets</th>' +
              "</tr></thead>" +
              "<tbody><tr>" +
              '<td class="ordersummarylist" style="text-align:center">' +
              parseFloat(obj6.data[0].lengthstickersizeselected).toFixed(2) +
              "</td>" +
              '<td class="ordersummarylist" style="text-align: right">' +
              parseFloat(obj6.data[0].bleedlength).toFixed(2) +
              "</td>" +
              '<td class="ordersummarylist" style="text-align:center">' +
              parseFloat(obj6.data[0].widthstickersizeselected).toFixed(2) +
              "</td>" +
              '<td class="ordersummarylist" style="text-align: right">' +
              parseFloat(obj6.data[0].bleedwidth).toFixed(2) +
              "</td>" +
              '<td class="ordersummarylist" style="text-align: right">' +
              parseFloat(obj6.data[0].bleedwidth).toFixed(2) +
              "</td>" +
              '<td class="ordersummarylist" style="text-align: right">' +
              parseFloat(obj6.data[0].stickerhor).toFixed(2) +
              "</td>" +
              '<td class="ordersummarylist" style="text-align: right">' +
              parseFloat(obj6.data[0].stickervertical).toFixed(2) +
              "</td>" +
              '<td class="ordersummarylist" style="text-align: right">' +
              parseFloat(obj6.data[0].trailsheet).toFixed(2) +
              "</td>" +
              '<td class="ordersummarylist" style="text-align: right">' +
              parseFloat(obj6.data[0].totalsheet).toFixed(2) +
              "</td></tr></tbody>",
          });
        })
      );
    } else {
      this.setState({
        stickerPrice: null,
        stickerUnitPrice: null,
        gstAmount: null,
        stickerDiscount: null,
        stickerCalculation: null,
      });
    }
  };

  handleChangeWidth(event) {
    this.setState(
      {
        selectedWidth: event.target.value,
      },
      () => {}
    );
  }

  handleChangeLength(event) {
    this.setState(
      {
        selectedLength: event.target.value,
      },
      () => {}
    );
  }

  onSubmitWhatsapp = () => {
    if (this.state.selectedShapeName != null) {
      if (this.state.selectedSize != null) {
        if (this.state.selectedQuantity != null) {
          if (this.state.selectedMaterialName != null) {
            window.open(
              `https://api.whatsapp.com/send?phone=919677027016&text= Requesting design assistance for Shape : ${this.state.selectedShapeName}  Size: ${this.state.selectedSize}
            Quantity:  ${this.state.selectedQuantity}
            Material Name:  ${this.state.selectedMaterialName} `
            );
          } else {
            Notiflix.Notify.failure("Please select material");
          }
        } else {
          Notiflix.Notify.failure("Please enter quantity");
        }
      } else {
        Notiflix.Notify.failure("Please enter/select size");
      }
    } else {
      Notiflix.Notify.failure("Please select shape");
    }
  };

  // ***** share icon ****

  sharesocialicon = (id) => {
    this.setState({
      closesharetoggle: !this.state.closesharetoggle,
      shareProductunicicon: id,
    });
    document.getElementById("shareicon").classList.toggle("active");
  };

  //  ***** For showing active shape items *****
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeSlide !== this.state.activeSlide) {
      // console.log("Navigating to slide:", this.state.activeSlide);
      this.sliderRef.current.slickGoTo(this.state.activeSlide);
    }
  }

  handleItemClick = (index) => {
    this.setState({ activeSlide: index });
  };

  handleBeforeChange = (current, next) => {
    // console.log("Before change - Current:", current, "Next:", next);
    this.setState({ activeSlide: next });
  };

  render() {
    // const { data } = this.props.location.state;

    // this.setState({
    //   selectedStickerImage: data.fld_image,
    // });

    // if (data && data.fld_image) {
    //   this.setState({
    //     selectedStickerImage: data.fld_image
    //   });
    // }

    const settings = {
      dots: false,
      infinite: false,
      arrows: true,
      speed: 800,
      autoplay: false,
      slidesToShow: 6,
      slidesToScroll: 1,

      initialSlide: this.state.activeSlide,
      beforeChange: this.handleBeforeChange,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            initialSlide: 1,
          },
        },
      ],
    };
    return (
      <>
        <div>
          <div
            className="col-md-12 m-show online-ordering"
            id="onilne-store"
            style={{
              display: this.state.onlineStoreBtn == true ? "block" : "none",
            }}
          >
            <a
              className="mx-lg-3 btn shopnowbtn"
              // href="/ordercustomboxes"
              href="/onlinestore"
              style={{
                background: "#59c5d1",
                borderRadius: "3px",
                color: "white",
                width: "85%",
                boxShadow: "0px 0px 12px rgba(0,0,0,.5)",
                fontSize: "22px",
              }}
            >
              Online Store
            </a>
          </div>
          <Header></Header>
          <section className="page-stickers">
            <div className="container">
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="top-breadcrumb">
                    <div className="w-100 text-center inner-pio-phn-logo justify-content-center d-md-none d-flex">
                      <img src={piologo} alt="pio-packaging" />
                    </div>
                    <ul>
                      <li className="breadcrumb-item">
                        <a href="/" className="text-dark">
                          Home
                        </a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="/#products" className="text-dark">
                          Products
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active text-dark"
                        aria-current="page"
                      >
                        {/* Stickers & Labels-Online */}
                        Custom Stickers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section pt-4">
            <div className="container-fluid sticker-label-details">
              <div className="row">
                <div className="col-12 text-center">
                  <h5 className="mb-3 pb-3 font-weight-bold">
                    Custom Stickers
                  </h5>
                </div>
                {/* <div className="col-md-12">
                <div className="section-title">
                  <h2>Stickers And Labels</h2>
                </div>
              </div> */}
                {/* <div className="col-md-6">
                <div className="section-display">
                  <img src={this.state.selectedStickerImage} />
                </div>
              </div> */}
                <div className="col-md-7">
                  <div className="gridCard strength2 p-3 mb-3 h-auto">
                    <div>
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <label
                            for="inputState"
                            className="col-sm-4 col-form-label d-block px-lg-2 px-0"
                          >
                            Select Shape{" "}
                            {/* <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                            }}
                          ></i> */}
                          </label>
                        </div>
                        <div className="select-material-wrapper select-material-wrapper-slider d-block w-100 slider-left-arrow-sticker">
                          <Slider {...settings} ref={this.sliderRef}>
                            {this.state.shapeData.map((stickerdata, index) => {
                              return (
                                <div
                                  key={stickerdata.id}
                                  className="select-material-item text-center"
                                  onClick={() => {
                                    this.setState({
                                      selectedBoxColor:
                                        stickerdata.fld_shape_name,
                                    });

                                    this.handleItemClick(index);
                                  }}
                                >
                                  <img
                                    className={`material-img ${
                                      index === this.state.activeSlide
                                        ? "active"
                                        : ""
                                    } mx-auto`}
                                    // className="material-img active mx-auto"
                                    src={stickerdata.fld_image}
                                    value={stickerdata.fld_id}
                                    title={stickerdata.fld_shape_name}
                                    onClick={(e) => {
                                      this.setState(
                                        {
                                          selectedShape:
                                            e.target.getAttribute("value"),
                                          selectedSticker:
                                            e.target.getAttribute("value"),
                                          selectedShapeName:
                                            e.target.getAttribute("title"),
                                          selectedStickerImage:
                                            e.target.getAttribute("src"),
                                        },
                                        () => {
                                          PostApiCall.postRequest(
                                            {
                                              recordCount: "*",
                                              WhereClause: ` where fld_shape_id = ${this.state.selectedSticker} and fld_status = 'Yes'`,
                                            },
                                            "GetStickerSizeMaster"
                                          ).then((results) =>
                                            results.json().then((obj) => {
                                              if (
                                                results.status == 200 ||
                                                results.status == 201
                                              ) {
                                                console.log(obj.data);
                                                this.setState({
                                                  sizeData: obj.data,
                                                });
                                                Notiflix.Loading.remove();
                                              }
                                            })
                                          );
                                          this.onPostPrice();
                                        }
                                      );
                                      // this.handleShow();
                                      this.showPrevImage();
                                    }}
                                  />
                                  <span
                                    className={
                                      this.state.selectedBoxColor ==
                                      stickerdata.fld_shape_name
                                        ? "material-name material-name-active"
                                        : "material-name"
                                    }
                                    // className={
                                    //  (data.fld_id ==
                                    //     verticalid || this.state.selectedBoxColor ==
                                    //       data.fld_shape_name )
                                    //     ? "material-name material-name-active"
                                    //     : "material-name"
                                    // }
                                  >
                                    {stickerdata.fld_shape_name}
                                  </span>
                                </div>
                              );
                            })}
                          </Slider>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12 d-flex">
                          <label
                            for="inputState"
                            className="col-sm-4 col-form-label px-lg-2 px-0"
                          >
                            Size (in mm){" "}
                          </label>

                          {/* {this.state.isCustomSize === "Y" && (
                            <MdCancel
                              className="crossbtn-sticker"
                              onClick={() =>
                                this.setState({
                                  isCustomSize: "N",
                                  selectedLength: null,
                                  selectedWidth: null,
                                })
                              }
                            />
                          )} */}

                          {this.state.isCustomSize === "N" ? (
                            <select
                              // onChange={(e) => {
                              //   this.setState(
                              //     {
                              //       selectedSize: e.target.value,
                              //       isCustomSize:
                              //         e.target.value === "Y" ? "Y" : "N",
                              //     },
                              //     () => {
                              //       this.onPostPrice();
                              //     }
                              //   );
                              // }}

                              onChange={(e) => {
                                // console.log(e.target.value,this.state.isCustomSize)
                                this.setState(
                                  {
                                    selectedSize: e.target.value,
                                    selectedLength:
                                      e.target.value === "Y"
                                        ? null
                                        : e.target.value?.split(" X ")[0],
                                    selectedWidth:
                                      e.target.value === "Y"
                                        ? null
                                        : e.target.value?.split(" X ")[1],
                                    isCustomSize:
                                      e.target.value === "Y" ? "Y" : "N",
                                  },
                                  () => {
                                    this.onPostPrice();
                                  }
                                );
                              }}
                              id="inputState"
                              class="form-control"
                            >
                              <option>Choose...</option>
                              {this.state.sizeData.map((data) => (
                                <option
                                  key={data.fld_size}
                                  value={data.fld_size}
                                >
                                  {data.fld_size}
                                </option>
                              ))}
                              <option key="Y" Value="Y">
                                Custom Size
                              </option>
                            </select>
                          ) : (
                            <>
                              <input
                                type="text"
                                class="form-control mr-2"
                                placeholder="Length"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                onChange={(e) => {
                                  this.setState(
                                    { selectedLength: e.target.value },
                                    () => {
                                      this.onPostPrice();
                                    }
                                  );
                                }}
                                value={this.state.selectedLength}
                              />
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Width"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                onChange={(e) => {
                                  this.setState(
                                    { selectedWidth: e.target.value },
                                    () => {
                                      this.onPostPrice();
                                    }
                                  );
                                }}
                                value={this.state.selectedWidth}
                              />
                              {this.state.isCustomSize === "Y" && (
                                <MdCancel
                                  className="crossbtn-sticker"
                                  onClick={() =>
                                    this.setState({
                                      isCustomSize: "N",
                                      selectedLength: null,
                                      selectedWidth: null,
                                    })
                                  }
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12 d-flex">
                          <label
                            for="inputState"
                            className="col-sm-4 col-form-label px-lg-2 px-0"
                          >
                            Quantity (min. 100 units){" "}
                          </label>
                          <div className="w-100">
                            <input
                              value={this.state.selectedQuantity}
                              onChange={(e) => {
                                this.setState(
                                  {
                                    selectedQuantity: e.target.value,
                                  },
                                  () => {
                                    this.onPostPrice();
                                  }
                                );
                              }}
                              onBlur={() => {
                                if (this.state.selectedQuantity < 100) {
                                  this.setState({
                                    minQty: "less",
                                  });
                                } else {
                                  this.setState({
                                    minQty: "greater",
                                  });
                                }
                              }}
                              id="inputState"
                              class="form-control"
                              placeholder="Quantity in numbers"
                            ></input>
                            {this.state.minQty === "less" ? (
                              <p className="text-danger">
                                Min quantity should be 100
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <label
                            for="inputState"
                            className="col-sm-4 col-form-label d-block px-lg-2 px-0 "
                          >
                            Select Material{" "}
                          </label>
                        </div>
                        <div className="select-material-wrapper select-material-wrapper-main">
                          {this.state.materialData.map((data) => {
                            return (
                              <div
                                onClick={(e) => {
                                  this.setState(
                                    {
                                      selectedMaterial: data.fld_id,
                                      selectedMaterialName:
                                        data.fld_material_name,
                                      selectedMaterialBox:
                                        data.fld_material_name,
                                    },
                                    () => {
                                      this.onPostPrice();
                                    }
                                  );
                                }}
                                className="select-material-item mb-3 mb-md-1"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      placement="top"
                                      className="in"
                                      id="tooltip-top"
                                    >
                                      {data.fld_material_description}
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    className="material-img active"
                                    src={data.fld_image}
                                    // title={data.fld_material_name}
                                  />
                                </OverlayTrigger>

                                <span
                                  class={
                                    this.state.selectedMaterialBox ==
                                    data.fld_material_name
                                      ? "material-name material-name-active"
                                      : "material-name"
                                  }
                                >
                                  {data.fld_material_name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div
                    className={
                      this.state.showPrevImage == true
                        ? "d-block mb-3 image-prev-box"
                        : "d-none"
                    }
                  >
                    <img
                      alt={this.state.selectedShapeName}
                      title={this.state.selectedShapeName}
                      className="img-fluid"
                      src={this.state.selectedStickerImage}
                    />
                  </div>
                  <div className="gridCard strength2 p-3 mb-3 h-auto">
                    <div>
                      <div className="border-top border-bottom d-flex justify-content-between pt-2 pb-0">
                        <p>
                          Total (GST Included) : <br />
                          <small style={{ fontSize: "11px" }}>
                            <span
                              class="pr-1"
                              style={{
                                fontSize: "12px",
                                fontWeight: "800",
                                color: "#ff0000",
                              }}
                            >
                              {" "}
                              Free Shipping{" "}
                            </span>{" "}
                            <br />
                            Anywhere in India
                          </small>
                        </p>
                        <div className="text-right">
                          <p className="sticker_product_price">
                            ₹{" "}
                            {this.state.stickerPrice != null
                              ? parseFloat(this.state.stickerPrice).toFixed(2)
                              : "0.00"}
                          </p>
                          <div class="d-flex flex-wrap align-items-center justify-content-end w-100">
                            <div class="d-flex flex-wrap justify-content-end sub-total price_calc_unit_price">
                              <small>
                                <span class="pr-1">Unit Price:</span>
                                <span class="unit_price">
                                  <b>
                                    ₹{" "}
                                    {this.state.stickerUnitPrice != null
                                      ? parseFloat(
                                          this.state.stickerUnitPrice
                                        ).toFixed(2)
                                      : "0.00"}
                                  </b>
                                </span>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <div className="border-top border-bottom d-flex justify-content-between pt-2 pb-0">
                        <p>Discount : </p>
                        <div className="text-right">
                          <p className="sticker_product_price">
                            ₹ {this.state.stickerDiscount}
                          </p>

                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="col-md-12 px-0 mb-3">
                    <div class="gridCard strength2">
                      <div class="d-lg-flex justify-content-between p-2 position-relative">
                        <button
                          onClick={() => {
                            // this.onSubmitWhatsapp();
                            this.handleShowPDFModal();
                          }}
                          class="btn designbtn2 active"
                        >
                          I have my own design
                        </button>
                        <button
                          onClick={() => {
                            // this.onSubmitWhatsapp();
                            this.sharesocialicon(true);
                          }}
                          style={{
                            display:
                              this.state.shareProductunicicon == null
                                ? "block"
                                : "none",
                          }}
                          // className={this.state.sharesocialicon == true ? "d-none" :  "d-block btn designbtn2 active"}
                          className="btn designbtn2 active"
                        >
                          I need design assistance
                        </button>
                        <button
                          onClick={() => {
                            // this.onSubmitWhatsapp();
                            this.sharesocialicon(null);
                          }}
                          className={
                            this.state.shareProductunicicon == null
                              ? "d-none"
                              : "d-block btn designbtn2 active"
                          }
                        >
                          I need design assistance
                        </button>

                        {/* <a
                          style={{
                            display:
                              this.state.shareProductunicicon == true
                                ? "block"
                                : "none",
                          }}
                          href="javascript:void(0)"
                          onClick={() => this.sharesocialicon(null)}
                          className="product-share-icon-img">
                          <i class="fa fa-xmark"></i>
                        </a>
                        <a
                          style={{
                            display:
                              this.state.shareProductunicicon == true
                                ? "none"
                                : "block",
                          }}
                          href="javascript:void(0)"
                          onClick={() => this.sharesocialicon(true)}
                          className="product-share-icon-img">
                          <i class="fa fa-share-alt"></i>
                        </a> */}
                        <div
                          className="product-share-icon sticker-share-icon"
                          style={{
                            display:
                              this.state.shareProductunicicon == true
                                ? "block"
                                : "none",
                          }}
                          id="shareicon"
                        >
                          <ul>
                            <li>
                              <a
                                href="https://wa.me/+919677027016"
                                target="_blank"
                              >
                                <i>
                                  <i class="fa-brands fa-whatsapp"></i>
                                </i>
                              </a>
                            </li>
                            <li>
                              <a href="tel:+919677027016">
                                <i className="fa fa-phone"></i>
                              </a>
                            </li>
                            <li>
                              <a href="mailto:info@piopackaging.com">
                                <i className="fa fa-envelope"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 px-0 mb-3">
                    <div class="gridCard strength2">
                      <div class="d-lg-flex justify-content-between p-2">
                        <div class="gridcardcheckbox">
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value={this.state.termscondition}
                            checked={
                              this.state.termscondition == "Yes" ? true : false
                            }
                            onChange={() => {
                              if (this.state.termscondition == "Yes") {
                                this.setState({
                                  termscondition: "No",
                                });
                              } else {
                                this.setState({
                                  termscondition: "Yes",
                                });
                              }
                            }}
                          />
                          <p for="vehicle1" class="m-0">
                            {" "}
                            &nbsp;I agree to the{" "}
                            <a href="/termsandcondition">
                              Terms &amp; Conditions
                            </a>
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            if (parseInt(this.state.selectedQuantity) >= 100) {
                              if (this.state.termscondition == "Yes") {
                                if (this.state.selectedShapeName != null) {
                                  if (this.state.selectedSize != null) {
                                    if (this.state.selectedQuantity != null) {
                                      if (
                                        this.state.selectedMaterialName != null
                                      ) {
                                        if (
                                          this.state.selectedQuantity >= 100
                                        ) {
                                          this.onOrderNow();
                                        } else {
                                          Notiflix.Notify.failure(
                                            "Min quantity should be 100"
                                          );
                                        }
                                      } else {
                                        Notiflix.Notify.failure(
                                          "Please select material"
                                        );
                                      }
                                    } else {
                                      Notiflix.Notify.failure(
                                        "Please enter quantity"
                                      );
                                    }
                                  } else {
                                    Notiflix.Notify.failure(
                                      "Please enter/select size"
                                    );
                                  }
                                } else {
                                  Notiflix.Notify.failure(
                                    "Please select shape"
                                  );
                                }
                              } else {
                                Notiflix.Notify.failure(
                                  "Please accept the terms and conditions."
                                );
                              }
                            } else {
                              Notiflix.Notify.failure(
                                "Minimum number of quantity 100"
                              );
                            }
                          }}
                          class="btn designbtn2 active"
                        >
                          Order Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <Products></Products> */}
          <Footer></Footer>
        </div>
        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          centered
          size="md"
        >
          <Modal.Body>
            <img
              className="img-fluid w-auto h-100"
              src={this.state.selectedStickerImage}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              class="btn designbtn2 active btn-lg float-none py-lg-2"
              onClick={this.handleClose}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showPDFModal}
          onHide={this.handlePDFModalClose}
          centered
          className="pdf-modal"
          size="lg"
        >
          <Modal.Header className="justify-content-between pb-0 border-0">
            <div>
              <a
                href={DesignInstructionsPdf}
                download="DesignInstructionsPdf"
                target="_blank"
                className="btn designbtn2 active me-3"
              >
                Download PDF
              </a>
              <ul className="d-flex">
                <li>
                  <a href="https://wa.me/+919677027016" target="_blank">
                    <i>
                      <i class="fa-brands fa-whatsapp"></i>
                    </i>
                  </a>
                </li>
                <li>
                  <a href="tel:+919677027016">
                    <i className="fa fa-phone"></i>
                  </a>
                </li>
                <li>
                  <a href="mailto:info@piopackaging.com">
                    <i className="fa fa-envelope"></i>
                  </a>
                </li>
              </ul>
            </div>
            <span
              onClick={() => {
                this.handleClosePDFModal();
              }}
              className="cursor-pointer"
            >
              <i class="fa-solid fa-xmark"></i>
            </span>
          </Modal.Header>
          <Modal.Body className="py-0">
            <div>
              <img src={DesignInstructions} alt="DesignInstructions"></img>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default StickersAndLabelsDetails;
