import React, { Component } from "react";
import ShopHeader from "../ShopHeader/ShopHeader";
import Footer from "../../Footer";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 10,
    };
  }

  componentDidMount() {
    setInterval(function () {
      window.location.replace("/ordercustomboxes");
    }, 10000);
  }

  render() {
    return (
      <React.Fragment>
        <ShopHeader />
        <section className="not-found">
          <div class="container section-padding">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title mt-lg-5 mt-4">
                  <p style={{ marginBottom: "30px" }}>
                    We are sorry but the page you are looking for does not
                    exist. You will be redirected to the homepage after few
                    seconds{" "}
                  </p>
                  <p>OR you can "click" on the image to go to the homepage.</p>
                  <img
                    className="not-found-img"
                    src="/assets/img/404.jpg"
                    onClick={() => {
                      this.props.history.getBack();
                    }}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default NotFound;
