import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Products from "./Products";
import MOQ from "./MOQ.PNG";
import { isMobile } from "react-device-detect";
import piologo from "../src/assets/image/pio-logo.png";

class SleevePackaging extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineStoreBtn: false,
    };
  }
  componentDidMount() {
    const store = this.context;
    {
      isMobile
        ? this.setState({
            onlineStoreBtn: true,
          })
        : this.setState({
            onlineStoreBtn: false,
          });
    }
  }
  render() {
    return (
      <div>
        <div
          class="col-md-12 m-show online-ordering"
          id="onilne-store"
          style={{
            display: this.state.onlineStoreBtn == true ? "block" : "none",
          }}
        >
          <a
            class="mx-lg-3 btn shopnowbtn"
            // href="/ordercustomboxes"
            href="/onlinestore"
            style={{
              background: "#59c5d1",
              borderRadius: "3px",
              color: "white",
              width: "85%",
              boxShadow: "0px 0px 12px rgba(0,0,0,.5)",
              fontSize: "22px",
            }}
          >
            Online Store
          </a>
        </div>
        <Header></Header>
        <section class="page-banner">
          <div class="container-fluid px-md-4 px-3">
            <div class="row mt-4">
              <div class="col-md-12">
                <div class="top-breadcrumb">
                  <div className="w-100 text-center inner-pio-phn-logo justify-content-center d-md-none d-flex">
                    <img src={piologo} alt="pio-packaging" />
                  </div>
                  <ul>
                    <li class="breadcrumb-item">
                      <a href="http://piopackaging.com/">Home</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a href="http://piopackaging.com/#products">Products</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Sleeve Packaging
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section">
          <div class="quantity-icon">
            <img src={MOQ} alt="" />
          </div>
          <div class="container-fluid px-md-4 px-3">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title text-center">
                  <h2>Sleeve Packaging</h2>
                </div>
              </div>
              <div class="col-md-12">
                <div class="inner-page-cont">
                  <p>
                    Sleeves for boxes and products are not just meant to provide
                    protection and content information of your products. With
                    high quality print processes and finishes it enhances the
                    product within by creating an instant appeal.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="sub-section-title sub-section-title-black">
                  <h2>Sample Products</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/sleeve-packaging/sleeve-packaging-01.PNG"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/sleeve-packaging/thumb01.jpg"
                        alt="Sleeve Packaging"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/sleeve-packaging/sleeve-packaging-02.jpg"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/sleeve-packaging/thumb02.jpg"
                        alt="Sleeve Packaging"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/sleeve-packaging/sleeve-packaging-03.PNG"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/sleeve-packaging/thumb03.jpg"
                        alt="Sleeve Packaging"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                  <div class="product-item-img">
                    <a
                      href="assets/img/sleeve-packaging/sleeve-packaging-04.PNG"
                      data-fancybox="images"
                    >
                      <img
                        class="img-fluid"
                        src="assets/img/sleeve-packaging/thumb04.jpg"
                        alt="Sleeve Packaging"
                      />

                      <div class="product-view-btn">
                        <i class="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Products></Products>
        <Footer></Footer>
      </div>
    );
  }
}

export default SleevePackaging;
