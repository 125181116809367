import React from 'react';
import ReactGA from "react-ga4";
ReactGA.initialize([
  {
    trackingId: "G-2VDZ0VM2P4",
  }
]);

class Footer extends React.Component {

  render() {
    return (
      <div>
        <section class="enquiry">
          <div class="container-fluid px-md-4 px-3">
            <div class="row">
              <div class="col-md-12">
                <div class="enquiry-box">
                  <div class="row">
                    <div class="col-lg-9 col-sm-8">
                      <div class="enquiry-text">
                        <h2>How can we help?</h2>
                        <p>
                          We would love to hear from you to understand your
                          requirements. You are welcome to send us your
                          enquiry and our sales team will get in touch with
                          you to provide cost effective attractive packaging
                          solutions.
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-4">
                      <div class="middle-box pull-right">
                        <div class="middle">
                          <div class="enquiry-btn">
                            <a
                              href="/enquiry"
                              type="button"
                              class="btn btn-enquiry btn-lg"
                            >
                              Enquire Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer class="footer" id="ContactUs">
          <div class="container-fluid px-md-4 px-3">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title text-center white">
                  <h2>Contact Us</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <div class="foot-col">
                  <div class="foot-titel mb-2">
                    <h2 className='mb-2'>Pio Packaging</h2>
                    <p className='mb-0 pb-0'>A unit of Pio Printers Pvt. Ltd.</p>
                  </div>
                  <div class="foot-cont">
                    <p className='my-0 py-0'>
                      A one stop shop for all Printing & Packaging needs.
                    </p>
                    <p className='mt-2'>
                      Let us know what you have in mind and we can create
                      outstanding quality packaging solutions for your
                      products.
                    </p>

                    <div class="foot-media">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/PioPackaging"
                            class="facebook"
                            target='blank'
                          >
                            <i class="fa fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/pio.packaging/"
                            class="instagram"
                            target='blank'
                          >
                            <i class="fa fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/pio-packaging"
                            class="linkedin"
                            target='blank'
                          >
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="foot-col">
                  <div class="foot-titel">
                    <h2>Link</h2>
                  </div>
                  <div class="foot-cont">
                    <ul>
                      <li>
                        <a href="http://piopackaging.com/#overview">
                          <i class="fa fa-cube"></i> Overview
                        </a>
                      </li>
                      <li>
                        <a href="/custom-packaging">
                          <i class="fa fa-cube"></i> Why Custom Packaging ?
                        </a>
                      </li>
                      <li>
                        <a href="http://piopackaging.com/#industries">
                          <i class="fa fa-cube"></i> Industries
                        </a>
                      </li>
                      <li>
                        <a href="http://piopackaging.com/#products">
                          <i class="fa fa-cube"></i> Products
                        </a>
                      </li>
                      <li>
                        <a href="/termsandcondition">
                          <i class="fa fa-cube"></i> Terms & Conditions
                        </a>
                      </li>
                      <li>
                        <a href="/privacypolicy">
                          <i class="fa fa-cube"></i> Privacy Policy
                        </a>
                      </li>
                      {/* <li><a href="/returnandrefundpolicy"><i class="fa fa-cube"></i> Refund Policy</a></li> */}
                      <li>
                        <a href="/faqs">
                          <i class="fa fa-cube"></i> FAQs
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="foot-col">
                  <div class="foot-titel">
                    <h2>Contact Info</h2>
                  </div>
                  <div class="foot-cont">
                    <h4>
                      <i class="fa fa-map-marker"></i> Address
                    </h4>
                    <p>
                      {" "}
                      83, East Mada Church Road, Royapuram, <br />
                      Chennai - 600 013 India.
                    </p>
                    <h4>
                      <i class="fa fa-envelope"></i> Email
                    </h4>
                    <p>
                      <a href="mailto:info@piopackaging.com">
                        info@piopackaging.com
                      </a>
                    </p>
                    <h4>
                      <i class="fa fa-phone"></i> Phone Number
                    </h4>
                    <p>
                      <i
                        class="fa fa-mobile"
                        style={{ margin: "0 4px", fontSize: "18px" }}
                      ></i>{" "}
                      <a href="tel:+919677027016" onClick={() => {
                        ReactGA.event({
                          category: "Call Button Click Mobile Footer",
                          action: "Call Initiated",
                          label: "MobileWebCall", // optional
                          nonInteraction: true, // optional, true/false
                          transport: "xhr", // optional, beacon/xhr/image
                        });
                      }}> +91 96770 27016</a>
                      <br />
                      <i
                        class="fa fa-mobile"
                        style={{ margin: "0 4px", fontSize: "18px" }}
                      ></i>
                      <a href="tel:+919840327012"> +91 98403 27012</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copy-text">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 text-center">
                  <p>
                    &copy;
                    {new Date().getFullYear()} Pio Printers Pvt. Ltd.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>

        {/* <div class="social-media">
                    <ul>
                        <li><a href="https://www.facebook.com/PioPackaging" target="_blank" class="facebook"><i class="fa fa-facebook-f"></i></a></li>
                        <li><a href="https://www.instagram.com/pio.packaging/" target="_blank" class="instagram"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/pio-packaging" target="_blank" class="linkedin"><i class="fa fa-linkedin"></i></a></li>
                    </ul>
                </div> */}
        <div class="pulse1"></div>
        <div class="pulse2"></div>
        <div class="whatsapp-icon">
          <a href="https://wa.me/+919677027016" target="_blank">
            {" "}
            <img src="/assets/img/whatsapp.png" />
            <div class="pulse-bg p-3 position-absolute rounded-circle"></div>
            <div class="pulse-bg-2 p-3 position-absolute rounded-circle"></div>
          </a>
        </div>
        <div class="call-icon d-block d-md-none">
          <a href="tel:+919677027016" onClick={() => {
            ReactGA.event({
              category: "Call Button Click Mobile Footer",
              action: "Call Initiated",
              label: "MobileWebCall", // optional
              nonInteraction: true, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            });
          }} target="_blank">
            <img src="/assets/img/call.png" />
            <div class="pulse-bg-blue p-3 position-absolute rounded-circle"></div>
            <div class="pulse-bg-2-blue p-3 position-absolute rounded-circle"></div>
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;