import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Products from "./Products";
import MOQ from "./MOQ.PNG";
import { isMobile } from "react-device-detect";
import piologo from "../src/assets/image/pio-logo.png";

class CustomPackaging extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineStoreBtn: false,
    };
  }
  componentDidMount() {
    const store = this.context;
    {
      isMobile
        ? this.setState({
            onlineStoreBtn: true,
          })
        : this.setState({
            onlineStoreBtn: false,
          });
    }
  }
  render() {
    return (
      <div>
        <div
          class="col-md-12 m-show online-ordering"
          id="onilne-store"
          style={{
            display: this.state.onlineStoreBtn == true ? "block" : "none",
          }}
        >
          <a
            class="mx-lg-3 btn shopnowbtn"
            // href="/ordercustomboxes"
            href="/onlinestore"
            style={{
              background: "#59c5d1",
              borderRadius: "3px",
              color: "white",
              width: "85%",
              boxShadow: "0px 0px 12px rgba(0,0,0,.5)",
              fontSize: "22px",
            }}
          >
            Online Store
          </a>
        </div>
        <Header></Header>
        <section class="page-banner">
          <div class="container-fluid px-md-4 px-3">
            <div class="row mt-4">
              <div class="col-md-12">
                <div class="top-breadcrumb">
                  <div className="w-100 text-center inner-pio-phn-logo justify-content-center d-md-none d-flex">
                    <img src={piologo} alt="pio-packaging"/>
                  </div>
                  <ul>
                    <li class="breadcrumb-item">
                      <a href="http://piopackaging.com">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Custom Packaging
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section custom-packaging">
          <div class="container-fluid px-md-4 px-3">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title text-start text-md-center pb-0 mb-0 mb-md-4">
                  <h2>Why is custom packaging important for your business?</h2>
                </div>
              </div>
              </div>
              <div class="row flex-column-reverse flex-lg-row">
              <div class="col-md-9">
                <div class="inner-page-cont">
                  <p>
                    The word custom can instantly scare small businesses.
                    Anything custom is instantly thought to be more costly than
                    standard ready-made boxes. So it is no shock
                    that custom packaging can feel out of reach for small
                    businesses and start-ups. They know they need some type of
                    product packaging, but why would they pay to customize
                    something that will just be thrown away?
                  </p>
                  <p>
                    The answer is simple, custom packaging isn’t as expensive as
                    people think. This is more important for e-commerce online
                    businesses since your packaging is your customer’s first
                    physical interaction and impression of your brand. Remember
                    the old saying; you only get one chance to make a good first
                    impression.
                  </p>
                  <p className="mb-0 pb-0">
                    Online companies don’t have the opportunity to physically
                    interact and impact a customer’s senses, that is, until a
                    customer orders online and receives a package in the mail he
                    will know nothing about you or your product. That shipping
                    box is your customer’s first physical interaction and
                    impression of you.
                  </p>
                </div>
              </div>
              <div class="col-md-3 mb-3 mb-md-1">
                <div class="custome-img">
                  <img src="assets/img/why-custom-02.jpg" alt="" />
                </div>
              </div>
              </div>
                <div class="row mt-md-5 mt-3">
                  <div class="col-md-3 mb-3 mb-md-1">
                    <div class="custome-img">
                      <img src="assets/img/why-custom-03.jpg" alt="" />
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="inner-page-cont">
                      <div class="section-title pb-0 mb-0 mb-md-4">
                        <h2 className="pb-md-0 mb-md-0">
                          Think of your packaging as your company’s brand
                          ambassador whose job is to tell the customer you are
                          special. What type of story do you want it to tell?
                        </h2>
                      </div>
                      <p>
                        There are many ways you can use custom packaging to
                        promote your product and increase customer brand
                        awareness. Use a unique box style or shape to standout,
                        even subtle differences will be noticed compared to
                        every other standard shipping box.
                      </p>
                      <p className="mb-0 pb-0">
                        Once you decide what style and materials best exemplify
                        your brand, adding graphics that support this message is
                        the key. Custom graphics aren’t limited to only the
                        exterior box either. A hot new trend is creating an
                        unforgettable ‘unboxing experience’. Customers will
                        share this ‘unboxing experience’ online, and if it is
                        eye-catching enough social media will spread your brand
                        like wildfire.
                      </p>
                    </div>
                  </div>
                </div>

              
                <div class="row mt-3 mt-md-5">
                  <div class="col-md-9">
                    <div class="inner-page-cont">
                      <div class="section-title pb-0 mb-0 mb-md-4">
                        <h2>
                          Custom packaging is within reach for small businesses!
                        </h2>
                      </div>
                      <p>
                        All of this sounds great, but is it really feasible for
                        small businesses financially? The answer is 100% yes!
                        That means you can have any custom shape, number of
                        colors, amount of graphics, special effects such as
                        gold, silver & holographic foil, embossed print and UV
                        Drip-off effects without any barriers of entry for small
                        companies and there is no reason why any company should
                        be settling for a standard brown box.
                      </p>
                      <p>
                        For great packaging ideas and if you’re interested in
                        how to make graphic packaging part of your brand
                        strategy give us a call at{" "}
                        <a href="tel:+91 96770 27016">+91 96770 27016</a> or
                        email us at{" "}
                        <a href="mailto:info@piopackaging.com">
                          info@piopackaging.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="custome-img">
                      <img src="assets/img/why-custom-01.jpg" alt="" />
                    </div>
                  </div>
                </div>
            
            </div>
        </section>
        <Footer></Footer>
      </div>
    );
  }
}

export default CustomPackaging;
