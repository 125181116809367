import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import MOQ from "./MOQ.PNG";
import { isMobile } from "react-device-detect";

class StickersAndLabels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineStoreBtn: false,
      stickerData: [
        {
          shapeImage:
            "https://www.piopackaging.com/assets/img/rigid-boxes/thumb03.jpg",
          shapeName: "round shape",
          shapePrice: 120,
        },
        {
          shapeImage:
            "https://www.piopackaging.com/assets/img/rigid-boxes/thumb02.jpg",
          shapeName: "Square/Rectangle",
          shapePrice: 150,
        },
        {
          shapeImage:
            "https://www.piopackaging.com/assets/img/rigid-boxes/thumb03.jpg",
          shapeName: "special shape",
          shapePrice: 110,
        },
      ],
    };
  }
  componentDidMount() {
    const store = this.context;
    {
      isMobile
        ? this.setState({
            onlineStoreBtn: true,
          })
        : this.setState({
            onlineStoreBtn: false,
          });
    }
  }
  render() {
    return (
      <div>
        <div
          className="col-md-12 m-show online-ordering"
          id="onilne-store"
          style={{
            display: this.state.onlineStoreBtn == true ? "block" : "none",
          }}
        >
          <a
            className="mx-lg-3 btn shopnowbtn"
            // href="/ordercustomboxes"
            href='/onlinestore'
            style={{
              background: "#59c5d1",
              borderRadius: "3px",
              color: "white",
              width: "85%",
              boxShadow: "0px 0px 12px rgba(0,0,0,.5)",
              fontSize: "22px",
            }}
          >
            Online Store
          </a>
        </div>
        <Header></Header>
        <section className="page-banner">
          <div className="container">
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="top-breadcrumb">
                  <ul>
                    <li className="breadcrumb-item">
                      <a href="https://piopackaging.com">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="https://piopackaging.com#products">Products</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Stickers And Labels
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          {/* <div className="quantity-icon">
            <img src={MOQ} />
          </div> */}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center">
                  <h2>Stickers And Labels</h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="inner-page-cont">
                  <p>
                    Shop for your ideal label shape & order your online labels
                    in minutes. Custom sizes at the right costs with lowest
                    order quantities in the industry for you to be sure before
                    placing bigger orders.
                  </p>
                  <p>
                    Want to repeat an order? <a href="#">Re-order</a>
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12">
                <div className="sub-section-title sub-section-title-black">
                  <h2>TYPES OF PRODUCTS</h2>
                </div>
              </div>
            </div> */}
            <div className="row pt-lg-5">
              {this.state.stickerData.map((data) => (
                <div className="col-lg-3 col-6 mb-4">
                  <div className="product-item">
                    <div className="product-item-img mb-2">
                      <a href="#">
                        <img
                          className="img-fluid"
                          src={data.shapeImage}
                          alt={data.shapeName}
                        />
                      </a>
                    </div>
                    <div className="product-item-name">
                      <p className="text-capitalize font-weight-bold text-dark">{data.shapeName}</p>
                    </div>
                    <div className="product-item-price">
                      <p className="">Starts at Rs.{" "}{data.shapePrice}</p>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className="col-lg-3 col-sm-6">
                <div className="product-item">
                  <div className="product-item-img">
                    <a
                      href="assets/img/rigid-boxes/rigid-boxes-03.png"
                      data-fancybox="images"
                    >
                      <img
                        className="img-fluid"
                        src="assets/img/rigid-boxes/thumb03.jpg"
                        alt="Blister Cards"
                      />

                      <div className="product-view-btn">
                        <i className="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="product-item">
                  <div className="product-item-img">
                    <a
                      href="assets/img/rigid-boxes/rigid-boxes-04.png"
                      data-fancybox="images"
                    >
                      <img
                        className="img-fluid"
                        src="assets/img/rigid-boxes/thumb04.jpg"
                        alt="Blister Cards"
                      />

                      <div className="product-view-btn">
                        <i className="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="product-item">
                  <div className="product-item-img">
                    <a
                      href="assets/img/rigid-boxes/rigid-boxes-05.jpg"
                      data-fancybox="images"
                    >
                      <img
                        className="img-fluid"
                        src="assets/img/rigid-boxes/thumb05.jpg"
                        alt="Blister Cards"
                      />

                      <div className="product-view-btn">
                        <i className="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        {/* <Products></Products> */}
        <Footer></Footer>
      </div>
    );
  }
}

export default StickersAndLabels;
