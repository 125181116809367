import React from "react";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga4";
import { BackgroundImage } from "react-image-and-background-image-fade";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-modal/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PostApiCall from "../../components/helper/Api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
ReactGA.initialize([
  {
    trackingId: "G-2VDZ0VM2P4",
  },
]);

class CustomPackaginBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineStoreBtn: false,
      // Custom boxes
      VerticleData: [],
      loading: true,
    };
  }
  componentDidMount() {
    // Custom boxes
    PostApiCall.postRequest(
      {
        whereClause: "where fld_status = 'Active'",
        recordCount: "*",
      },
      "GetProductMaster"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          VerticleData: obj.data,
          loading: false,
        });
      })
    );
  }
  render() {
    const { VerticleData, loading } = this.state;
    const settings = {
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: false,
      autoplaySpeed: 2000,
      rows: 2,
      // lazyLoad: 'ondemand',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 700,
          settings: {
            rows: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div class="container-fluid px-md-4 px-3">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title text-center mb-md-4 mb-3">
              <h2>Choose your custom packaging box</h2>
            </div>
          </div>
        </div>
        {/* **** Desktop view ********** */}
        <Slider
          {...settings}
          className="home-online-store-products desktop-view-custom-box-item"
          id="custombox"
        >
          {loading
            ? Array(12)
                .fill()
                .map((_, i) => (
                  <div className="grid-item" key={i}>
                    <Skeleton
                      height={30}
                      width={`98%`}
                      style={{ marginBottom: "12px", paddingRight: "10px" }}
                    />
                    <Skeleton height={295} width={349} />
                    <Skeleton
                      height={30}
                      width={`60%`}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    />
                  </div>
                ))
            : VerticleData.map((data, i) => (
                <div
                  class="col-12 px-0 mb-2"
                  onClick={() => {
                    this.setState({
                      activeproduct: data.fld_productid,
                    });
                  }}
                  onMouseOver={() => {
                    this.setState({
                      showShopNowbutton: data.fld_productid,
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      showShopNowbutton: "",
                    });
                  }}
                >
                  <div
                    className={
                      this.state.activeproduct == data.fld_productid
                        ? "product-cat-box-active-border product-cat-box mx-1 mb-1"
                        : "product-cat-box mx-1 mb-1"
                    }
                  >
                    <div class="product-cat-box-txt">
                      <p
                        className="product-cat-box-name-txt shop-phn-img-heading-home-desktop product-content-align"
                        style={{ textTransform: "capitalize" }}
                      >
                        {data.fld_itemname}
                      </p>
                    </div>
                    <div class="product-cat-box-img">
                      <a
                        onClick={() => {
                          ReactGA.event({
                            category: "ProductClick-Home",
                            action: data.fld_itemname,
                            label: data.fld_itemname, // optional
                            nonInteraction: true, // optional, true/false
                            transport: "xhr", // optional, beacon/xhr/image
                          });
                          window.location.href = `${process.env.PUBLIC_URL}/customizeyourbox/${data.fld_productid}`;
                        }}
                      >
                        <BackgroundImage
                          src={data.fld_itemimage}
                          width="243px"
                          height="212px"
                          isResponsive
                          className="img-fluid"
                          alt="Retail Food Packaging"
                          style={{
                            backgroundSize: "cover",
                            backgroundPosition: "center top",
                          }}
                          lazyLoad
                        />
                      </a>
                    </div>
                    <div class="product-cat-box-txt">
                      <button
                        type="button"
                        class="btn btn-primary centered"
                        style={{
                          display:
                            this.state.showShopNowbutton == data.fld_productid
                              ? "block"
                              : "none",
                          background: "#9b9c9e",
                          border: "1px solid #9b9c9e",
                        }}
                        onClick={() => {
                          window.location.href = `${process.env.PUBLIC_URL}/customizeyourbox/${data.fld_productid}`;
                        }}
                      >
                        Shop Now
                      </button>
                    </div>
                    <a
                      className="btn shopnowbtn2 shop-phn-btn shop-phn-img-heading-home-btn-desktop"
                      href={`${process.env.PUBLIC_URL}/customizeyourbox/${data.fld_productid}`}
                    >
                      Customize your order
                    </a>
                  </div>
                </div>
              ))}
        </Slider>

        {/* ********** phone view ************* */}
        <div className="vertical-grid-box white-scrollbar phn-view-custom-box-item">
          {this.state.VerticleData.map((data, i) => (
            <div className="Vertical-wrap px-0 mb-md-3 mb-2 d-flex align-items-center justify-content-center">
              <div
                onClick={() => {
                  this.setState({
                    activeproduct: data.fld_productid,
                  });
                }}
                onMouseOver={() => {
                  this.setState({
                    showShopNowbutton: data.fld_productid,
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    showShopNowbutton: "",
                  });
                }}
              >
                <div
                  className={
                    this.state.activeproduct == data.fld_productid
                      ? "product-cat-box-active-border product-cat-box mx-1 mb-0 pb-0"
                      : "product-cat-box mx-1 mb-0 pb-0"
                  }
                >
                  <div class="product-cat-box-txt">
                    <p className="product-cat-box-name-txt shop-phn-img-heading-phn-home product-content-align">
                      {data.fld_itemname}
                    </p>
                  </div>
                  <div class="product-cat-box-img">
                    <a
                      onClick={() => {
                        ReactGA.event({
                          category: "ProductClick-Home",
                          action: data.fld_itemname,
                          label: data.fld_itemname, // optional
                          nonInteraction: true, // optional, true/false
                          transport: "xhr", // optional, beacon/xhr/image
                        });
                        window.location.href = `${process.env.PUBLIC_URL}/customizeyourbox/${data.fld_productid}`;
                      }}
                    >
                      <BackgroundImage
                        src={data.fld_itemimage}
                        width="243px"
                        height="212px"
                        isResponsive
                        className="img-fluid"
                        alt="Retail Food Packaging"
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "center top",
                        }}
                        lazyLoad
                      />
                    </a>
                  </div>
                  <div class="product-cat-box-txt">
                    <button
                      type="button"
                      class="btn btn-primary centered"
                      style={{
                        display:
                          this.state.showShopNowbutton == data.fld_productid
                            ? "block"
                            : "none",
                        background: "#9b9c9e",
                        border: "1px solid #9b9c9e",
                      }}
                      onClick={() => {
                        window.location.href = `${process.env.PUBLIC_URL}/customizeyourbox/${data.fld_productid}`;
                      }}
                    >
                      Shop Now
                    </button>
                  </div>
                  <a
                    className="btn shopnowbtn2 shop-phn-img-heading-phn-home-btn"
                    href={`${process.env.PUBLIC_URL}/customizeyourbox/${data.fld_productid}`}
                  >
                    Customize your order
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default CustomPackaginBox;
