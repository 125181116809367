import React from "react";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-modal/styles.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Header from "../../Header";
import Footer from "../../Footer";
import CustomSticker from "../CommonStickerCustomBox/Stickeronline";
import CustomPackaginBoxOnline from "../CommonStickerCustomBox/CustomPackaginBoxOnline";
import piologo from "../../assets/image/pio-logo.png";

import ReactGA from "react-ga4";
ReactGA.initialize([
  {
    trackingId: "G-2VDZ0VM2P4",
  },
]);

class OnlineStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineStoreBtn: false,
      // Custom boxes
      VerticleData: [],
      // for sticker
      shapeData: [],
    };
  }
  render() {
    return (
      <div>
        <div
          className="col-md-12 m-show online-ordering"
          id="onilne-store"
          style={{
            display: this.state.onlineStoreBtn == true ? "block" : "none",
          }}
        >
          <a
            className="mx-lg-3 btn shopnowbtn"
            href="/onlinestore"
            style={{
              background: "#59c5d1",
              borderRadius: "3px",
              color: "white",
              width: "85%",
              boxShadow: "0px 0px 12px rgba(0,0,0,.5)",
              fontSize: "22px",
            }}
          >
            Online Store
          </a>
        </div>
        <Header />

        <section className="page-banner">
          <div className="container-fluid px-md-4 px-3">
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="top-breadcrumb">
                  <div className="w-100 text-center inner-pio-phn-logo justify-content-center d-md-none d-flex">
                    <img src={piologo} alt="pio-packaging" />
                  </div>
                  <ul>
                    <li className="breadcrumb-item">
                      <a href="/ordercustomboxes">Custom Packaging Box</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/ordercustomstickers">Custom Stickers</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ********** Online store componenet ********** */}
        <section class="section Product section-online-store " id="product">
          <CustomPackaginBoxOnline />
          <CustomSticker />
        </section>

        <Footer />
      </div>
    );
  }
}

export default OnlineStore;
