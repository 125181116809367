import React from 'react';
import Header from './Header';
import Footer from './Footer';




class PrivacyPolicy extends React.Component {


    render() {
        return (

            <div className="App">
                <Header></Header>

                <div class="privacy-title termsconditiontitle  section-title container">
                    <h4>Privacy Policy of Pio Packaging – a unit of Pio Printers Pvt Ltd</h4>
                    <div class="title-divider"></div>

                </div>
                <div class="container">


                    <div class="row marginbtm-240">



                        <div class="col-md-12 privacy-policy disclaimer-box">
                            <p class="our">At Pio Printers Pvt Ltd, we take your personal privacy very seriously. Protecting your online privacy is important to us, not just from a business perspective, but from an ethical one as well. We therefore are proud to share with you our honest, open and 100% understandable privacy and security policy. We do not sell our database of information about you. That is NOT our revenue model.
                            </p>
                            <p class="such">We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent and we only use your information as described in the Privacy Policy. We view protection of your privacy as a very important community principle. We understand clearly that you and Your Information is one of our most important assets. We store and process your personal Information on our servers which are protected by physical as well as technological security devices. We use third parties to verify and certify our privacy principles. If you object to your Information being transferred or used in this way please do not use the Site.</p>
                            <p class="such">
                                We collect "personal" information from you when you provide it to us. For example, if you purchase a product from us, we may collect your name, mailing address, telephone number and email address. If you sign up to receive a newsletter, we will collect your email address. If you take advantage of special services offered by us, we may collect other personal information about you. We use your personal information for internal purposes such as processing and keeping you informed of your order.                                   </p>
                            <p>
                                Under no circumstances do we rent, trade or share your personal Information that we have collected with any other company for their marketing purposes without your consent. We reserve the right to communicate your personal information to any third party that makes a legally-compliant request for its disclosure. Otherwise, however, we will not disclose your name, address and other information which identifies you personally to any third party without your consent. We reserve the right to collect general demographic and other anonymous information that does not personally identify you. This information is not associated with your personally identifiable information and cannot be linked to you personally.
                            </p>
                            <p>
                                If you are no longer interested in receiving e-mail announcements and other marketing information from us, or you want us to remove any Information that we have collected about you, please e-mail your request to info@piopackaging.com
                            </p>
                            <p>
                                We use cookies and other technologies such as pixel tags and clear gifs to store certain types of information each time you visit any page on our website. Cookies enable this site to recognize the information you have consented to give to this website and help us determine what portions of this website are most appropriate for your professional needs. We may also use cookies to serve advertising banners to you. These banners may be served by us or by a third party on our behalf. These cookies will not contain any personal information.
                            </p>
                            <p>
                                Whether you want your web browser to accept cookies or not is up to you. If you haven't changed your computer's settings, most likely your browser already accepts cookies. If you choose to decline cookies, you may not be able to fully experience all features of the site. You can also delete your browser cookies of disable them entirely. But this may significantly impact your experience with our site and may make parts of our website non-functional or inaccessible. We recommend that you leave them turned on.
                            </p>
                            <p className='mb-5'>
                                We use third-party service providers to serve ads on our behalf across the Internet and sometimes on this site. They may collect anonymous information about your visits to our website, and your interaction with our products and services. They may also use information about your visits to this and other sites to target advertisements for goods and services. This anonymous information is collected through the use of a pixel tag, which is industry standard technology used by most major websites. No personally identifiable information is collected or used in this process. They do not know the name, phone number, address, email address, or any personally identifying information about the user.
                            </p>

                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </div>
        );
    }
}

export default PrivacyPolicy;
