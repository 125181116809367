import React from "react";
import { isMobile } from "react-device-detect";
import Footer from "./Footer";
import Header from "./Header";
import MOQ from "./MOQ.PNG";
import Products from "./Products";
import piologo from "../src/assets/image/pio-logo.png";

class BespokePackaging extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineStoreBtn: false,
    };
  }
  componentDidMount() {
    const store = this.context;
    {
      isMobile
        ? this.setState({
            onlineStoreBtn: true,
          })
        : this.setState({
            onlineStoreBtn: false,
          });
    }
  }
  render() {
    return (
      <div>
        <div
          className="col-md-12 m-show online-ordering"
          id="onilne-store"
          style={{
            display: this.state.onlineStoreBtn == true ? "block" : "none",
          }}
        >
          <a
            className="mx-lg-3 btn shopnowbtn"
            // href="/ordercustomboxes"
            href="/onlinestore"
            style={{
              background: "#59c5d1",
              borderRadius: "3px",
              color: "white",
              width: "85%",
              boxShadow: "0px 0px 12px rgba(0,0,0,.5)",
              fontSize: "22px",
            }}
          >
            Online Store
          </a>
        </div>
        <Header></Header>
        <section className="page-banner">
          <div className="container-fluid px-md-4 px-3">
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="top-breadcrumb">
                  <div className="w-100 text-center inner-pio-phn-logo justify-content-center d-md-none d-flex">
                    <img src={piologo} alt="pio-packaging" />
                  </div>
                  <ul>
                    <li className="breadcrumb-item">
                      <a href="http://piopackaging.com/">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="http://piopackaging.com/#products">Products</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Bespoke Packaging
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="quantity-icon">
            <img src={MOQ} alt="" />
          </div>
          <div className="container-fluid px-md-4 px-3">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center">
                  <h2>Bespoke Packaging</h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="inner-page-cont">
                  <p>
                    What is Bespoke Packaging? Bespoke packaging is
                    custom-designed, luxury packaging that is unique to you and
                    your brand. It is the ideal way to showcase your
                    high-quality product to its best, making it even more
                    desirable than it already is. Custom-printed and available
                    in limitless sizes, styles and colours, the best bespoke
                    packaging can act as an extension of your brand and be a
                    great marketing ploy.
                  </p>

                  <ul>
                    <li>
                      <i className="fa fa-dot-circle-o"></i> UV printing on
                      Metalised PET boards
                    </li>
                    <li>
                      <i className="fa fa-dot-circle-o"></i> UV Drip-off effect
                    </li>
                    <li>
                      <i className="fa fa-dot-circle-o"></i> Special coatings
                      like velvet finish, sand effect &amp; soft touch
                    </li>
                    <li>
                      <i className="fa fa-dot-circle-o"></i> Hot foil stamping
                      in gold, silver, rainbow and holographic effects
                    </li>
                    <li>
                      <i className="fa fa-dot-circle-o"></i> Embossing and
                      De-bossing effects
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="sub-section-title sub-section-title-black">
                  <h2>Sample Products</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="product-item">
                  <div className="product-item-img">
                    <a
                      href="assets/img/bespoke-packaging/bespoke-packaging-02.PNG"
                      data-fancybox="images"
                    >
                      <img
                        className="img-fluid"
                        src="assets/img/bespoke-packaging/thumb02.jpg"
                        alt="Bespoke Packaging"
                      />

                      <div className="product-view-btn">
                        <i className="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="product-item">
                  <div className="product-item-img">
                    <a
                      href="assets/img/bespoke-packaging/bespoke-packaging-03.JPG"
                      data-fancybox="images"
                    >
                      <img
                        className="img-fluid"
                        src="assets/img/bespoke-packaging/thumb03.jpg"
                        alt="Bespoke Packaging"
                      />

                      <div className="product-view-btn">
                        <i className="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="product-item">
                  <div className="product-item-img">
                    <a
                      href="assets/img/bespoke-packaging/bespoke-packaging-04.JPG"
                      data-fancybox="images"
                    >
                      <img
                        className="img-fluid"
                        src="assets/img/bespoke-packaging/thumb04.jpg"
                        alt="Bespoke Packaging"
                      />

                      <div className="product-view-btn">
                        <i className="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="product-item">
                  <div className="product-item-img">
                    <a
                      href="assets/img/bespoke-packaging/bespoke-packaging-05.JPG"
                      data-fancybox="images"
                    >
                      <img
                        className="img-fluid"
                        src="assets/img/bespoke-packaging/thumb05.jpg"
                        alt="Bespoke Packaging"
                      />

                      <div className="product-view-btn">
                        <i className="fa fa-eye"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Products></Products>
        <Footer></Footer>
      </div>
    );
  }
}

export default BespokePackaging;
