import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Route, Router, Switch } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import BaseLidBoxes from "./BaseLidBoxes";
import BespokePackaging from "./BespokePackaging";
import BlisterCards from "./BlisterCards";
import CreativePackaging from "./CreativePackaging";
import CustomPackaging from "./CustomPackaging";
import DisplayPackaging from "./DisplayPackaging";
import DrawerTypeBox from "./DrawerTypeBox";
import enquiry from "./enquiry";
import HingedLidBoxes from "./HingedLidBoxes";
import LabelsandStickers from "./LabelsandStickers";
import Manage from "./manage";
import MonoCartons from "./MonoCartons";
import Cart from "./Pages/ShopCart/ShopCart";
import PaperCarryBags from "./PaperCarryBags";
import ProductVisibilityPackaging from "./ProductVisibilityPackaging";
import RetailFoodPackaging from "./RetailFoodPackaging";
import RigidBoxes from "./RigidBoxes";
import SleevePackaging from "./SleevePackaging";
import StickersAndLabels from "./StickersAndLabels";
import StickersAndLabelsDetails from "./StickersAndLabelsDetails";
import Tags from "./Tags";
// import Checkout from './Pages/ShoppingCheckout/ShoppingCheckout';
import AddressBookForm from "./CustomerDashboard/AddressBookForm";
import CustomerDashboard from "./CustomerDashboard/CustomerDashboard";
import EditAddress from "./CustomerDashboard/EditAddress";
import EditProfile from "./CustomerDashboard/EditProfile";
import gussetpackaging from "./GussetPackaging";
import NotFound from "./Pages/NotFound/NotFound";
import Login from "./Pages/ShopDetailPage/login";
import ProductDetailPage from "./Pages/ShopDetailPage/ProductDetailPage";
import Register from "./Pages/ShopDetailPage/Register";
import Shop from "./Pages/ShopHomepage/Shop";

import OrderHistory from "./CustomerDashboard/OrderHistory";
import OrderSample from "./Pages/ShopDetailPage/ordersample";
import Checkout from "./Pages/ShopDetailPage/OrderSummary";
import selectAddress from "./Pages/ShopDetailPage/selectshippingaddess";

import Privacypolicy from "./Privacypolicy";
import RefundPolicy from "./RefundPolicy";
import Termscondition from "./Termscondition";

import ViewInvoice from "./CustomerDashboard/ViewInvoice";
import MyOrders from "./MyOrders";
import PaymentFail from "./Pages/ShopDetailPage/PaymentFail";
import PaymentProcess from "./Pages/ShopDetailPage/paymentprocess";
import PaymentSuccess from "./Pages/ShopDetailPage/PaymentSuccess";

import Downloadkeyline from "./Pages/ShopDetailPage/downloadkeyline";

import Provider from "./components/context/store";
import FAQs from "./FAQs";
import PioMobileDesign from "./Pages/PioMobile/Design";
import PioMobile from "./Pages/PioMobile/PioMobile";
import forgetPassword from "./Pages/ShopDetailPage/forgetPassword";

import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoute";
import DownloadOrderForm from "./DownloadOrderForm";
import Address from "./Pages/ProfileComponent/Address";
import Order from "./Pages/ProfileComponent/Order";
import Profile from "./Pages/ProfileComponent/Profile";

// **** For New Sticker Modules ****
import onlinestore from "./Pages/Stickers/onlinestore";

const history = createBrowserHistory();

ReactDOM.render(
  <Provider>
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={App}></Route>
        <Route exact path="/mono-cartons" component={MonoCartons}></Route>
        <ProtectedRoutes
          exact
          path="/profile"
          component={Profile}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <ProtectedRoutes
          exact
          path="/orders"
          component={Order}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <ProtectedRoutes
          exact
          path="/address"
          component={Address}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <Route
          exact
          path="/bespoke-packaging"
          component={BespokePackaging}
        ></Route>
        <Route
          exact
          path="/creative-packaging"
          component={CreativePackaging}
        ></Route>
        <Route
          exact
          path="/retail-food-packaging"
          component={RetailFoodPackaging}
        ></Route>
        <Route
          exact
          path="/product-visibility-packaging"
          component={ProductVisibilityPackaging}
        ></Route>
        <Route
          exact
          path="/sleeve-packaging"
          component={SleevePackaging}
        ></Route>
        <Route
          exact
          path="/hinged-lid-boxes"
          component={HingedLidBoxes}
        ></Route>
        <Route exact path="/drawer-type-box" component={DrawerTypeBox}></Route>
        <Route
          exact
          path="/base-and-lid-boxes"
          component={BaseLidBoxes}
        ></Route>
        <Route
          exact
          path="/display-packaging"
          component={DisplayPackaging}
        ></Route>
        <Route exact path="/blister-cards" component={BlisterCards}></Route>
        <Route
          exact
          path="/labels-and-stickers"
          component={LabelsandStickers}
        ></Route>
        <Route
          exact
          path="/paper-carry-bags"
          component={PaperCarryBags}
        ></Route>
        <Route
          exact
          path="/custom-packaging"
          component={CustomPackaging}
        ></Route>
        <Route exact path="/tags" component={Tags}></Route>
        <Route exact path="/rigid-boxes" component={RigidBoxes}></Route>
        <Route
          exact
          path="/stickers-and-labels"
          component={StickersAndLabels}
        ></Route>
        <Route
          exact
          path="/ordercustomstickers"
          component={StickersAndLabelsDetails}
        ></Route>

        {/* ********* For Sticker Id ********* */}
        {/* <Route
          exact
          path={`${process.env.PUBLIC_URL}/ordercustomstickers/:verticalid`}
          component={StickersAndLabelsDetails}
        ></Route> */}


        <Route
          exact
          path={`${process.env.PUBLIC_URL}/manage`}
          component={Manage}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/enquiry`}
          component={enquiry}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/gusset-packaging`}
          component={gussetpackaging}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/ordercustomboxes`}
          component={Shop}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          component={Login}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/register`}
          component={Register}
        ></Route>
        <ProtectedRoutes
          exact
          path={`${process.env.PUBLIC_URL}/address_book_form`}
          component={AddressBookForm}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <ProtectedRoutes
          exact
          path={`${process.env.PUBLIC_URL}/customerprofile`}
          component={CustomerDashboard}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <ProtectedRoutes
          exact
          path={`${process.env.PUBLIC_URL}/edit-address`}
          component={EditAddress}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <ProtectedRoutes
          exact
          path={`${process.env.PUBLIC_URL}/edit-profile`}
          component={EditProfile}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/cart`}
          component={Cart}
        ></Route>
        <ProtectedRoutes
          exact
          path={`${process.env.PUBLIC_URL}/checkout`}
          component={Checkout}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/customizeyourbox/:verticalid`}
          component={ProductDetailPage}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/customizemybox/:variantid`}
          component={ProductDetailPage}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/downloadorderform/:orderId`}
          component={DownloadOrderForm}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/ordersample/:verticalid/:boxname`}
          component={OrderSample}
        ></Route>
        <ProtectedRoutes
          exact
          path={`${process.env.PUBLIC_URL}/selectaddress`}
          component={selectAddress}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/termsandcondition`}
          component={Termscondition}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/returnandrefundpolicy`}
          component={RefundPolicy}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/privacypolicy`}
          component={Privacypolicy}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/faqs`}
          component={FAQs}
        ></Route>
        <ProtectedRoutes
          exact
          path={`${process.env.PUBLIC_URL}/order-history`}
          component={OrderHistory}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/paymentprocess/:txnid/:mipid`}
          component={PaymentProcess}
        ></Route>
        <Route
          exact
          path={"/paymentfail/:txnid/:mipid"}
          component={PaymentFail}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/paymentsuccess/:txnid/:mipid`}
          component={PaymentSuccess}
        ></Route>
        <ProtectedRoutes
          exact
          path={`${process.env.PUBLIC_URL}/view-invoice-form/:orderid`}
          component={ViewInvoice}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <ProtectedRoutes
          exact
          path={`${process.env.PUBLIC_URL}/my-orders`}
          component={MyOrders}
          isAuth={localStorage.getItem("CustomerData") == null ? false : true}
        ></ProtectedRoutes>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/forgetpassword`}
          component={forgetPassword}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/downloadkeyline/:orderid`}
          component={Downloadkeyline}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pio-mobile`}
          component={PioMobile}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pio-mobile/design`}
          component={PioMobileDesign}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pio-mobile/quantity`}
          component={PioMobileDesign}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pio-mobile/summary`}
          component={PioMobileDesign}
        ></Route>

        {/* ***** New routes for online store ******** */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/onlinestore`}
          component={onlinestore}
        ></Route>

        <Route component={NotFound}></Route>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
