import { createContext, useState } from "react";

export const store = createContext()

const Provider = ({ children }) => {
    const [selectedBox, setselectedBox] = useState("");
    const [selectedDesign, setselectedDesign] = useState("");
    const [selectedOuterCoating, setselectedOuterCoating] = useState("");
    const [selectedInnerCoating, setselectedInnerCoating] = useState("");
    const [selectedQualityBoard, setselectedQualityBoard] = useState("");
    const [activateClass, setactivateClass] = useState("profile")
    const [SelectedBoxName, setSelectedBoxName] = useState("")
    const [ShowOnlineOrder, setShowOnlineOrder] = useState(true)



    return (
        <store.Provider value={{
            selectedBox, setselectedBox, selectedDesign, setselectedDesign, selectedOuterCoating, setselectedOuterCoating,
            selectedInnerCoating, setselectedInnerCoating, selectedQualityBoard, setselectedQualityBoard, activateClass, setactivateClass, SelectedBoxName, setSelectedBoxName, ShowOnlineOrder, setShowOnlineOrder
        }}>
            {children}
        </store.Provider>
    )
}

export default Provider;
