
var urlString = "https://www.piopackaging.com/API/";
//var urlString = "https://www.piopackaging.com/APIDEV/";
// var urlString = "http://localhost:8051/API/";
// var urlString = "http://192.168.1.46:8051/API/";

const GetApiCall = {
	getRequest(url) {
		// console.log(urlString+url)
		return fetch(urlString + url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "*",
				"Content-Type": "application/json",

			},
		});
	},
};

export default GetApiCall;
